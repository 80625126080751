<template>
  <div class="pl-16 pr-2">
    <v-card :min-height="windowH" class="mx-auto mt-3 mb-9 bgTransparente" max-width="calc(100% - 10px)" elevation="0">
      <v-card-title class="ml-15 pt-8 pl-0 black--text">Provedores cadastrados</v-card-title>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="toogleRegisterPartnerDilog" v-bind="attrs" v-on="on" class="mt-13 mr-10" color="primary" fab dark
            :fixed="responsiveSize < 500" :absolute="responsiveSize >= 500" :bottom="responsiveSize < 500"
            :top="responsiveSize >= 500" right>
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Adicionar Provedor</span>
      </v-tooltip>
      <v-data-table light :headers="headers" :items="provaiders" :items-per-page="10"
        class="elevation-0 opacityTable mx-15">
        <template v-slot:item.document="{ item }">
          {{ item.document | formatCnpjCpf }}
        </template>
        <template v-slot:item.phone="{ item }">
          {{ item.phone | formatPhones }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="btMicro px-1 mr-3" @click="editPartner(item)" x-small color="primary"
                dark><v-icon center x-small> edit </v-icon></v-btn>
            </template>
            <span class="miniTtip">Editar provedor</span>
          </v-tooltip>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="btMicro px-1" @click="confirmDelPartner(item)" x-small color="red"
                dark><v-icon center x-small> mdi-delete-forever </v-icon></v-btn>
            </template>
            <span class="miniTtip">Excluir provedor</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <RegisterPartner :getProviders="getProviders" :updatePartners="updatePartners" :editPartnerData="editPartnerData"
      :contacts="contacts" :confirmDelContact="confirmDelContact" :updateContactList="updateContactList"
      :toogleRegisterPartnerDilog="toogleRegisterPartnerDilog" :registerPartnerDilog="registerPartnerDilog" />
  </div>
</template>

<script>
import RegisterPartner from "./RegisterPartner";
import MyFilters from "../../filters/genericFilters";
export default {
  computed: {
    responsiveSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
  },
  beforeMount() {
    this.getHeightPage();
  },
  mounted() {
    this.getProviders();
  },
  created() {
    window.addEventListener("resize", this.getHeightPage);
  },
  destroyed() {
    window.removeEventListener("resize", this.getHeightPage);
  },
  methods: {
    updatePartners(data) {
      //console.log("update");
      let index = this.provaiders.findIndex(
        (provider) => provider.id === data.id
      );
      if (index > -1) {
        this.provaiders.splice(index, 1);
        this.provaiders.splice(index, 0, data);
      } else {
        this.provaiders.push(data);
      }
    },
    editPartner(data) {
      this.contacts = data.partner_contacts;
      this.editPartnerData = { ...data };
      this.registerPartnerDilog = true;
    },
    async getProviderById(id) {
      let line;
      var obj = {
        url: "/api/partner/getById",
        query: { id: id },
        method: "post",
      };
      await this.$store.dispatch("request/doRequest", obj).then((response) => {
        line = response.data[0];
      });
      return line;
    },
    updateContactList(contact, id) {
      console.log("idcontact");
      let _this = this;
      if (id) {
        this.getProviderById(id).then((line) => {
          let index = _this.provaiders.findIndex(
            (provider) => provider.id === id
          );
          _this.provaiders.splice(index, 1);
          _this.provaiders.splice(index, 0, line);
        });
      }
      if (contact.index != null) {
        this.contacts.splice(contact.index, 1);
        this.contacts.splice(contact.index, 0, contact);
      } else {
        this.contacts.push(contact);
      }
    },
    confirmDelContact(data, i) {
      let obj = {
        message: `Você deseja excluir o contato <b>${data.name}</b>?`,
        title: "Excluir Contato",
        callback: (_) => {
          this.delContact(data, i);
        },
        type: "warning",
        btConfirm: { name: "Sim", show: true },
        btCancel: { name: "Não", show: true },
      };
      this.$store.commit("confirmMessage/showConfirmMessage", obj);
    },
    delContact(data, i) {
      if (data.id) {
        let obj = {
          url: "/api/partner/contact/delete",
          query: { id: data.id },
          method: "post",
        };
        this.$store.dispatch("request/doRequest", obj).then((response) => {
          let opts = {
            message: `Contato <b>${data.name}</b> removido com sucesso.`,
            type: "success",
          };
          this.$store.commit("showAlert", opts);
        });
      }
      this.contacts.splice(i, 1);
    },
    getHeightPage() {
      // console.log("altura", window.innerHeight - 125);
      this.windowH = window.innerHeight - 125;
    },
    getProviders() {
      var obj = {
        url: "/api/partner/get-all",
        query: null,
        method: "get",
      };
      //console.log(obj);
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        // console.log(response);
        this.provaiders = [...response.data.filter((partner) => {
          if (!partner.company_id) {
            return partner
          }
        })];
      });
    },
    toogleRegisterPartnerDilog() {
      this.editPartnerData = null;
      this.contacts = [];
      this.registerPartnerDilog = !this.registerPartnerDilog;
    },
    confirmDelPartner(data) {
      let obj = {
        message: `Você deseja excluir o provedor <b>${data.name}</b>?`,
        title: "Excluir Provedor",
        callback: (_) => {
          this.delItem(data);
        },
        type: "warning",
        btConfirm: { name: "Sim", show: true },
        btCancel: { name: "Não", show: true },
      };
      this.$store.commit("confirmMessage/showConfirmMessage", obj);
    },
    delItem(data) {
      let obj = {
        url: "/api/partner/delete",
        query: { id: data.id },
        method: "post",
      };
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        let index = this.provaiders.findIndex(
          (provider) => provider.id === data.id
        );
        this.provaiders.splice(index, 1);
        let opts = {
          message: `Provedor <b>${data.name}</b> removido com sucesso.`,
          type: "success",
        };
        this.$store.commit("showAlert", opts);
      });
    },
  },
  data() {
    return {
      editPartnerData: null,
      contacts: [],
      show: false,
      windowH: "580",
      registerPartnerDilog: false,
      headers: [
        { text: "Nome", value: "name", sortable: true },
        { text: "CNPJ/CPF", value: "document", sortable: false },
        { text: "Telefone", value: "phone", sortable: false },
        { text: "Ações", value: "actions", sortable: false },
      ],
      provaiders: [],
    };
  },
  components: {
    RegisterPartner,
  },
};
</script>

<style>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}

.bgTransparente {
  background-color: rgba(255, 255, 255, 1) !important;
}

.bgTransparente .theme--light.v-data-table.opacityTable {
  background-color: transparent !important;
  color: #000 !important;
}

.bgTransparente .theme--light.v-data-table.opacityTable th {
  color: #000 !important;
}
</style>