<template>
  <v-data-table
    :footer-props="{
      'items-per-page-text': 'Provedores por página',
    }"
    :loading="false"
    :headers="headers"
    :items="contacts"
    :items-per-page="5"
    class="elevation-0"
  >
    <template v-slot:no-data>
      <div class="text-xs-center" style="height: 250px; padding-top: 112px">
        Não há dados
      </div>
    </template>
    <template v-slot:item.phone="{ item }">
      {{ item.phone | formatPhones }}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="btMicro px-1 mr-3"
            @click="editContact(item, contacts.indexOf(item))"
            x-small
            color="primary"
            dark
            ><v-icon center x-small> edit </v-icon></v-btn
          >
        </template>
        <span class="miniTtip">Editar contato</span>
      </v-tooltip>
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="btMicro px-1"
            @click="confirmDelContact(item, contacts.indexOf(item))"
            x-small
            color="red"
            dark
            ><v-icon center x-small> mdi-delete-forever </v-icon></v-btn
          >
        </template>
        <span class="miniTtip">Excluir contato</span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>

<script>
import MyFilters from "../../../filters/genericFilters";
export default {
  props: {
    editContact: Function,
    contacts: Array,
    confirmDelContact: Function,
  },
  data() {
    return {
      headers: [
        {
          text: "Nome",
          align: "start",
          sortable: true,
          value: "name",
        },
        { text: "Email", value: "email", sortable: false },
        { text: "Telefone", value: "phone", sortable: false },
        { text: "Ações", value: "actions", sortable: false },
      ],
    };
  },
};
</script>

<style>
</style>