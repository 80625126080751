var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-0",attrs:{"footer-props":{
    'items-per-page-text': 'Provedores por página',
  },"loading":false,"headers":_vm.headers,"items":_vm.contacts,"items-per-page":5},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('div',{staticClass:"text-xs-center",staticStyle:{"height":"250px","padding-top":"112px"}},[_vm._v(" Não há dados ")])]},proxy:true},{key:"item.phone",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPhones")(item.phone))+" ")]}},{key:"item.actions",fn:function(ref){
  var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btMicro px-1 mr-3",attrs:{"x-small":"","color":"primary","dark":""},on:{"click":function($event){_vm.editContact(item, _vm.contacts.indexOf(item))}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"center":"","x-small":""}},[_vm._v(" edit ")])],1)]}}],null,true)},[_c('span',{staticClass:"miniTtip"},[_vm._v("Editar contato")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btMicro px-1",attrs:{"x-small":"","color":"red","dark":""},on:{"click":function($event){_vm.confirmDelContact(item, _vm.contacts.indexOf(item))}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"center":"","x-small":""}},[_vm._v(" mdi-delete-forever ")])],1)]}}],null,true)},[_c('span',{staticClass:"miniTtip"},[_vm._v("Excluir contato")])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }