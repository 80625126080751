<template>
  <v-row justify="center">
    <v-dialog light v-model="contactFormDialog" persistent max-width="700">
      <v-card>
        <v-card-title class="headline px-2 pb-0">
          <v-app-bar flat color="rgba(0, 0, 0, 0)">
            <v-toolbar-title class="text-h5 pl-0" style="height: 45px">
              {{ formData.id ? "Editar contato" : "Novo contato" }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- <v-fab-transition> -->
            <v-btn @click="toogleContactFormDialog" class="mt-2" color="red" fab icon dark x-small absolute top right>
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <!-- </v-fab-transition> -->
          </v-app-bar>
        </v-card-title>
        <v-card-text>
          <v-tabs v-model="tab" style="border-bottom: 1px solid #ccc">
            <v-tab>Dados do contato</v-tab>
            <v-tab :disabled="!formData.id || !formData.access_permission">Permissões do contato</v-tab>
            <v-tooltip right v-if="!formData.id" max-width="180">
              <template v-slot:activator="{ on, attrs }">
                <v-icon size="18" style="cursor: pointer; margin-left: -10px" color="primary" dark v-bind="attrs"
                  v-on="on">
                  help_outline
                </v-icon>
              </template>
              <span>Para definir as permissões é necessário primeiramente salvar o
                contato.</span>
            </v-tooltip>
          </v-tabs>
          <v-tabs-items v-model="tab" style="min-height: 280px">
            <v-tab-item>
              <v-form ref="contactForm" v-model="valid">
                <v-container class="mt-2">
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field v-model="formData.name" :rules="$store.state.formRules.required" label="Nome completo"
                        required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="7">
                      <v-text-field v-model="formData.email" :rules="$store.state.formRules.emailRules" label="Email"
                        required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="5">
                      <div>
                        <v-text-field-simplemask v-model="formData.phone" v-bind:label="'Telefone'" v-bind:properties="{
                          rules: [
                            $store.state.formRules.phoneRules[0](
                              formData.phone
                            ),
                          ],
                          prefix: '',
                          suffix: '',
                          readonly: false,
                          disabled: false,
                          outlined: false,
                          clearable: false,
                          placeholder: '',
                        }" v-bind:options="{
  inputMask:
    (formData.phone ? formData.phone.length : '') < 11
      ? '(##) ####-#####'
      : '(##) # ####-####',
  outputMask: '############',
  empty: null,
  applyAfter: false,
  alphanumeric: true,
  lowerCase: false,
}" />
                      </div>
                    </v-col>
                    <v-col cols="12" md="6"><v-switch v-model="formData.contract_signatory"
                        label="Autorizar assinatura de contratos"></v-switch></v-col>
                    <v-col cols="12" md="6"><v-switch v-model="formData.access_permission"
                        label="Receber credenciais de acesso"></v-switch></v-col>
                    <!-- <v-col cols="12" md="12"
                  ><v-switch
                    v-model="formData.sendAuthCredentials"
                    label="Habilitar envio de email com as credencias de autenticação"
                  ></v-switch
                ></v-col> -->
                  </v-row>
                </v-container>
              </v-form>
            </v-tab-item>

            <v-tab-item>
              <PermissionsList :accessPermissions="formData.access_permission" :contactFormDialog="contactFormDialog"
                ref="permissionsForm" :services="services" :contact_id="formData.id" />
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-card-actions class="px-0 mx-3" style="border-top: 1px solid rgb(220, 220, 220)">
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="toogleContactFormDialog">
            Fechar
          </v-btn>
          <v-btn color="green" text @click="saveContact"> Salvar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
const resetContactPartnerForm = {
  id: null,
  index: null,
  name: "",
  email: "",
  phone: "",
  access_permission: false,
  contract_signatory: false,
  // sendAuthCredentials: false,
};
import PermissionsList from "./PermissionsList.vue";
export default {
  methods: {
    saveContact() {
      let contactIndex =
        this.formData.index != null ? this.formData.index : null;
      let url = "";
      if (!this.$refs.contactForm.validate()) {
        return;
      }
      if (!this.partnerId) {
        this.updateContactList(this.formData);
        this.toogleContactFormDialog();
        return;
      }
      this.formData.partner_id = this.partnerId;
      if (this.formData.id) {
        url = "/api/partner/contact/update";
      } else {
        url = "/api/partner/contact/create";
      }
      var obj = {
        noLoader: true,
        url: url,
        query: this.formData,
        method: "post",
      };
      let _this = this;
      this.$store.commit("showLoader", {
        circle: true,
      });
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        //console.log("----", _this.$refs.permissionsForm);
        if (
          this.$refs.permissionsForm &&
          _this.$refs.permissionsForm.service_id
        ) {
          this.$refs.permissionsForm.savePermissions();
        } else {
          this.$store.commit("hideLoader");
        }

        if (contactIndex != null) {
          response.data.index = contactIndex;
        }
        this.updateContactList(response.data, this.partnerId);
        this.toogleContactFormDialog();
        let opts = {
          message: `Contato ${response.data.name} ${contactIndex != null ? "alterado" : "criado"
            } com sucesso`,
          type: "success",
        };
        this.$store.commit("showAlert", opts);
      });
    },
  },
  watch: {
    contactFormDialog(v) {
      // console.log("frm contact");
      if (!v) {
        this.$refs.contactForm.resetValidation();
        this.formData = { ...resetContactPartnerForm };
      } else {
        this.tab = 0
        if (this.editContactData) {
          this.formData = this.editContactData;
        }
      }
    },
  },
  props: {
    services: Array,
    editContactData: Object,
    partnerId: Number,
    updateContactList: Function,
    contactFormDialog: Boolean,
    toogleContactFormDialog: Function,
  },
  data() {
    return {
      tab: null,
      valid: true,
      formData: { ...resetContactPartnerForm },
    };
  },
  components: {
    PermissionsList,
  },
};
</script>

<style></style>