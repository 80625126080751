<template>
  <v-row class="pt-5">
    <v-col cols="12" md="6">
      <v-select @change="getPermissions" v-model="service_id" :items="services" item-text="name" item-value="id"
        label="Escolha um aplicativo" clearable></v-select>
    </v-col>
    <v-col cols="12" md="6">
      <div class="mt-3 grey--text text--darken-2" style="font-size: 12px; line-height: initial">
        As permissões do contato definim quais atividades ele poderá realizar
        quando estiver logado no sistema. Por exemplo: Adicionar, editar e
        excluir subscrição.
      </div>
    </v-col>
    <v-col cols="12" md="12" class="px-15" v-if="!service_id">
      <h3 class="
          mx-10
          grey--text
          text--darken-1
          font-weight-light
          text-center
          px-15
        ">
        Selecione acima um aplicativo para definir o nível de acessso que o
        contato terá.
      </h3>
    </v-col>
    <v-row class="px-10" v-if="service_id">
      <v-col cols="12" md="6" class="">
        <v-switch v-model="permissionList.add" label="Adicionar subscrição"></v-switch>
      </v-col>
      <v-col cols="12" md="6" class="">
        <v-switch v-model="permissionList.edit" label="Editar subscrição"></v-switch>
      </v-col>
      <v-col cols="12" md="6" class="">
        <v-switch v-model="permissionList.bock" label="Bloquear subscrição"></v-switch>
      </v-col>
      <v-col cols="12" md="6" class="">
        <v-switch v-model="permissionList.cancel" label="Cancelar subscrição"></v-switch>
      </v-col>
      <v-col cols="12" md="6" class="">
        <v-switch v-model="permissionList.changePassword" label="Alterar senha"></v-switch>
      </v-col> </v-row></v-row>
</template>

<script>
const resetPermissions = {
  add: false,
  edit: false,
  bock: false,
  cancel: false,
  changePassword: false,
};
export default {
  watch: {
    accessPermissions(v) {
      if (!v) {
        this.service_id = null;
        this.permissionList = { ...resetPermissions };
      }
    },
    contactFormDialog(v) {
      //console.log("watch");
      if (!v) {
        this.service_id = null;
        this.permissionList = { ...resetPermissions };
      }
    },
  },
  props: {
    contactFormDialog: Boolean,
    services: Array,
    contact_id: Number,
    accessPermissions: Boolean
  },
  methods: {
    savePermissions() {
      let query = {
        contact_id: this.contact_id,
        service_id: this.service_id,
        permissions: [this.permissionList],
      };
      var obj = {
        url: "/api/partner/contact/permissions/createOrUpdate",
        query: query,
        method: "post",
      };
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        console.log(response.data);
      });
    },
    getPermissions() {
      if (!this.service_id) {
        this.permissionList = { ...resetPermissions };
        return;
      }
      //console.log("aaa");
      let query = {
        service_id: this.service_id,
        contact_id: this.contact_id,
      };
      var obj = {
        url: "/api/partner/contact/permissions/get-all",
        query: query,
        method: "post",
      };
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        this.permissionList = { ...response.data };
        // console.log(response.data);
      });
    },
  },
  data() {
    return {
      service_id: null,
      permissionList: { ...resetPermissions },
    };
  },
};
</script>

<style></style>