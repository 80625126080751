var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"light":"","persistent":"","max-width":"700"},model:{value:(_vm.contactFormDialog),callback:function ($$v) {_vm.contactFormDialog=$$v},expression:"contactFormDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline px-2 pb-0"},[_c('v-app-bar',{attrs:{"flat":"","color":"rgba(0, 0, 0, 0)"}},[_c('v-toolbar-title',{staticClass:"text-h5 pl-0",staticStyle:{"height":"45px"}},[_vm._v(" "+_vm._s(_vm.formData.id ? "Editar contato" : "Novo contato")+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"mt-2",attrs:{"color":"red","fab":"","icon":"","dark":"","x-small":"","absolute":"","top":"","right":""},on:{"click":_vm.toogleContactFormDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',[_c('v-tabs',{staticStyle:{"border-bottom":"1px solid #ccc"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("Dados do contato")]),_c('v-tab',{attrs:{"disabled":!_vm.formData.id || !_vm.formData.access_permission}},[_vm._v("Permissões do contato")]),(!_vm.formData.id)?_c('v-tooltip',{attrs:{"right":"","max-width":"180"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"cursor":"pointer","margin-left":"-10px"},attrs:{"size":"18","color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" help_outline ")])]}}],null,false,2383178168)},[_c('span',[_vm._v("Para definir as permissões é necessário primeiramente salvar o contato.")])]):_vm._e()],1),_c('v-tabs-items',{staticStyle:{"min-height":"280px"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-form',{ref:"contactForm",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{staticClass:"mt-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"rules":_vm.$store.state.formRules.required,"label":"Nome completo","required":""},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('v-text-field',{attrs:{"rules":_vm.$store.state.formRules.emailRules,"label":"Email","required":""},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}})],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('div',[_c('v-text-field-simplemask',{attrs:{"label":'Telefone',"properties":{
                          rules: [
                            _vm.$store.state.formRules.phoneRules[0](
                              _vm.formData.phone
                            ) ],
                          prefix: '',
                          suffix: '',
                          readonly: false,
                          disabled: false,
                          outlined: false,
                          clearable: false,
                          placeholder: '',
                        },"options":{
  inputMask:
    (_vm.formData.phone ? _vm.formData.phone.length : '') < 11
      ? '(##) ####-#####'
      : '(##) # ####-####',
  outputMask: '############',
  empty: null,
  applyAfter: false,
  alphanumeric: true,
  lowerCase: false,
}},model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}})],1)]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-switch',{attrs:{"label":"Autorizar assinatura de contratos"},model:{value:(_vm.formData.contract_signatory),callback:function ($$v) {_vm.$set(_vm.formData, "contract_signatory", $$v)},expression:"formData.contract_signatory"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-switch',{attrs:{"label":"Receber credenciais de acesso"},model:{value:(_vm.formData.access_permission),callback:function ($$v) {_vm.$set(_vm.formData, "access_permission", $$v)},expression:"formData.access_permission"}})],1)],1)],1)],1)],1),_c('v-tab-item',[_c('PermissionsList',{ref:"permissionsForm",attrs:{"accessPermissions":_vm.formData.access_permission,"contactFormDialog":_vm.contactFormDialog,"services":_vm.services,"contact_id":_vm.formData.id}})],1)],1)],1),_c('v-card-actions',{staticClass:"px-0 mx-3",staticStyle:{"border-top":"1px solid rgb(220, 220, 220)"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.toogleContactFormDialog}},[_vm._v(" Fechar ")]),_c('v-btn',{attrs:{"color":"green","text":""},on:{"click":_vm.saveContact}},[_vm._v(" Salvar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }